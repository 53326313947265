import React from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';

import { GatsbyImage } from 'gatsby-plugin-image';

import { Helmet } from 'react-helmet';
import {
  graphql,
  Link as GatsbyLink,
} from 'gatsby';

import { MathJax, MathJaxContext } from 'better-react-mathjax';

import rehypeReact from 'rehype-react';

import classNames from 'classnames';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../html/style.css';

const useStyles = makeStyles((theme) => ({
  image: {
    borderRadius: '.3rem !important',
    overflow: 'hidden',
    margin: '6px 3px !important',
    filter: 'brightness(0.95)',
    verticalAlign: 'middle !important',
  },
  figcaption: {
    marginTop: '1em',
    lineHeight: 1.75,
  },
  list: {
    marginTop: '0.2em',
    marginBottom: '1em !important',
    display: 'block',
  },
  orderedList: {
    listStyle: 'decimal outside',
    paddingLeft: '2.5em', // 40
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
    },
  },
  unorderedList: {
    listStyle: 'disc outside',
    paddingLeft: '2em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1em',
    },
  },
  listItem: {
    display: 'list-item',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
  },
  mathjaxRefLink: {
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const localFiles = {};

const MappedTitleToSEO = ({ children }) => <SEO title={children.toString().replace(/[ –]*Krzysztof Dobosz$/, '')} />;
MappedTitleToSEO.propTypes = {
  children: PropTypes.node.isRequired,
};

const H1Typography = ({ children, ...rest }) => <Typography variant="h1" component="h1" align="center" gutterBottom {...rest}>{children}</Typography>;
H1Typography.propTypes = {
  children: PropTypes.node.isRequired,
};

const H2Typography = ({ children, ...rest }) => <Typography variant="h2" component="h2" gutterBottom {...rest}>{children}</Typography>;
H2Typography.propTypes = {
  children: PropTypes.node.isRequired,
};

const H3Typography = ({ children, ...rest }) => <Typography variant="h3" component="h3" gutterBottom {...rest}>{children}</Typography>;
H3Typography.propTypes = {
  children: PropTypes.node.isRequired,
};

const H4Typography = ({ children, ...rest }) => <Typography variant="h4" component="h4" gutterBottom {...rest}>{children}</Typography>;
H4Typography.propTypes = {
  children: PropTypes.node.isRequired,
};

const H5Typography = ({ children, ...rest }) => <Typography variant="h5" component="h5" gutterBottom {...rest}>{children}</Typography>;
H5Typography.propTypes = {
  children: PropTypes.node.isRequired,
};

const PTypography = ({ children, ...rest }) => <Typography variant="body1" component="p" paragraph {...rest}>{children}</Typography>;
PTypography.propTypes = {
  children: PropTypes.node.isRequired,
};

const SmallTypography = ({ children, ...rest }) => <Typography variant="caption" component="small" {...rest}>{children}</Typography>;
SmallTypography.propTypes = {
  children: PropTypes.node.isRequired,
};

const FigCaptionTypography = ({ children, ...rest }) => {
  const classes = useStyles();
  return <Typography variant="body2" component="figcaption" className={classes.figcaption} {...rest}>{children}</Typography>;
};
FigCaptionTypography.propTypes = {
  children: PropTypes.node.isRequired,
};

const MappedGatsbyImage = ({ src, alt, className, style, ...rest }) => {
  const classes = useStyles();

  if (localFiles[src] === undefined) { // if it's not a local file
    return (
      <img
        src={src}
        alt={alt}
        className={classNames(classes.image, className)}
        style={style}
        {...rest}
      />
    );
  }

  if (localFiles[src].static) { // if publicURL
    return (
      <img
        src={localFiles[src].target}
        alt={alt}
        className={classNames(classes.image, className)}
        style={style}
        {...rest}
      />
    );
  }

  return (
    <GatsbyImage
      image={localFiles[src].target}
      alt={alt}
      // imgClassName={className}
      // className must be added to container too (in case of size adjustment in original CSS):
      className={classNames(classes.image, className)}
      imgStyle={style}
      {...rest}
    />
  );
};
MappedGatsbyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
};
MappedGatsbyImage.defaultProps = {
  className: '',
  style: {},
};

const MappedSource = ({ type, src, ...rest }) => {
  const properSrc = (localFiles[src] !== undefined && type.indexOf('video/') >= 0) ? localFiles[src].target : src;
  return <source src={properSrc} type={type} {...rest} />;
};
MappedSource.propTypes = {
  type: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

const OrderedList = ({ children, className, style, type, ...rest }) => {
  const classes = useStyles();
  const computedStyle = { ...style };
  switch (type) {
    case 'a': computedStyle.listStyleType = 'lower-alpha'; break;
    case 'A': computedStyle.listStyleType = 'upper-alpha'; break;
    case 'i': computedStyle.listStyleType = 'lower-roman'; break;
    case 'I': computedStyle.listStyleType = 'upper-roman'; break;
    // TODO: other types
    default:
  }
  return (
    <List
      component="ol"
      className={[className, classes.list, classes.orderedList]}
      style={computedStyle}
      {...rest}
    >
      {children}
    </List>
  );
};
OrderedList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.object),
  type: PropTypes.string,
};
OrderedList.defaultProps = {
  className: '',
  style: {},
  type: '',
};

const UnorderedList = ({ children, ...rest }) => {
  const classes = useStyles();
  return <List component="ul" className={[classes.list, classes.unorderedList]} {...rest}>{children}</List>;
};
UnorderedList.propTypes = {
  children: PropTypes.node.isRequired,
};

const MappedListItem = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <ListItem component="li" disableGutters className={classes.listItem} {...rest}>
      <ListItemText>{children}</ListItemText>
    </ListItem>
  );
};
MappedListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

const MappedLink = ({ children, href, rel, ...rest }) => {
  if (localFiles[href]) {
    return <Link href={localFiles[href].target} {...rest}>{children}</Link>;
  }

  if (href.match(/^http/)) {
    return <Link href={href} target="_blank" rel="noreferrer" {...rest}>{children}</Link>;
  }

  return <Link component={GatsbyLink} to={href} rel={rel} {...rest}>{children}</Link>;
};
MappedLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  rel: PropTypes.string.isRequired,
};

const EmptyComponent = () => React.Fragment;

const trimNewLinesAndWhiteSpaces = (value, { beginning = false, ending = false }) => {
  let result = value;
  if (typeof value === 'string') {
    if (beginning) result = value.replace(/^\s*(\r?\n|\r)+/gm, '');
    // comments end with only white spaces (so * instead of + before last \s)
    if (ending) result = value.replace(/(\r?\n|\r)*\s*$/gm, '');
  }
  return result;
};

const replaceMarkdownLink = (child) => {
  if (typeof child === 'object' && child.props && child.props.className?.includes('comment')) {
    const comment = child.props.children[0];
    const match = comment.match(/(.*)\[([^\]]+)\]\(([^)]+)\)(.*)/);
    if (match !== null && match.length === 5) {
      const preText = match[1];
      const linkHref = match[3];
      const linkContent = match[2];
      const postText = match[4];
      child.props.children[0] = preText;
      child.props.children[1] = <MappedLink href={linkHref}>{linkContent}</MappedLink>;
      child.props.children[2] = postText;
    }
  }
};

const replaceLatexDirective = (child) => {
  if (typeof child === 'string' && child.match(/(^|\s)(\${1,2})((?:\\.|.)*)\2/) != null) {
    return <MathJaxComponent inline tex={child} />;
  }
  return child;
};

const CodeComponent = ({ children, ...rest }) => {
  // removing new lines and white spaces at the begining and end of <code> element
  // (as plugin remove-initial-line-feed or normalize-whitespace)
  children[0] = trimNewLinesAndWhiteSpaces(children[0], { beginning: true });
  const lastIndex = React.Children.count(children) - 1;
  children[lastIndex] = trimNewLinesAndWhiteSpaces(children[lastIndex], { ending: true });

  React.Children.forEach(children, (child, index) => {
    // works only for links in comments in markdown style [text](url)
    // TODO: other links as in prism-autolinker (or make a gatsby plugin for this)
    if (child.props?.className?.includes('style')) { // style span embedded inside language-html code tag
      if (child.props.children[0].props?.className?.includes('language-css')) {
        React.Children.forEach(child.props.children[0].props.children, (csschild) => {
          replaceMarkdownLink(csschild);
        });
      }
    } else {
      replaceMarkdownLink(child);
      children[index] = replaceLatexDirective(child);
    }
  });

  return (
    <code {...rest}>{children}</code>
  );
};
CodeComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const config = {
  chtml: {
    scale: 1.09,
  },
  loader: { load: ['[tex]/html'] },
  tex: {
    tags: 'ams',
    packages: { '[+]': ['html'] },
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
  },
};

const MathJaxComponent = ({ tex, inline }) => {
  const classes = useStyles();
  return (
    <MathJaxContext config={config}>
      <MathJax dynamic inline={inline} className={classes.mathjaxRefLink}>{tex}</MathJax>
    </MathJaxContext>
  );
};
MathJaxComponent.propTypes = {
  tex: PropTypes.string.isRequired,
  inline: PropTypes.bool,
};
MathJaxComponent.defaultProps = {
  inline: false,
};

const DivComponent = ({ children, className, ...rest }) => {
  if (className?.includes('math-display')) {
    return <MathJaxComponent tex={children.toString()} />;
  }
  return <div className={className} {...rest}>{children}</div>;
};
DivComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
DivComponent.defaultProps = {
  className: '',
};

const SpanComponent = ({ children, className, ...rest }) => {
  if (className?.includes('math-inline')) {
    return <MathJaxComponent inline tex={children.toString()} />;
  }
  return <span className={className} {...rest}>{children}</span>;
};
SpanComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
SpanComponent.defaultProps = {
  className: '',
};

// eslint doesn't allow for a constructor starting with a small letter:
const RehypeReact = rehypeReact;

const renderAst = new RehypeReact({
  Fragment: React.Fragment,
  createElement: React.createElement,
  components: {
    meta: EmptyComponent, // remove useless tags
    link: EmptyComponent,
    title: MappedTitleToSEO,
    script: EmptyComponent,
    h1: H1Typography,
    h2: H2Typography,
    h3: H3Typography,
    h4: H4Typography,
    h5: H5Typography,
    p: PTypography,
    small: SmallTypography,
    figcaption: FigCaptionTypography,
    ol: OrderedList,
    ul: UnorderedList,
    li: MappedListItem,
    img: MappedGatsbyImage,
    a: MappedLink,
    source: MappedSource,
    code: CodeComponent,
    div: DivComponent,
    span: SpanComponent,
  },
}).Compiler;

// more generic way than slice(1, -1) => may not contain trailing slash etc.
const replaceSlugFromPath = (path, slugWithoutSlashes) => path.replace(new RegExp(`/?${slugWithoutSlashes}/?`), '');
const replaceSlashesFromSlug = (slug) => slug.replace(/^\/|\/$/g, '');

export default function HtmlReactRehyped({
  data: { htmlRehype, gatsbyImageFiles, publicURLFiles },
}) {
  const slugWithoutSlashes = replaceSlashesFromSlug(htmlRehype.fields.slug);

  gatsbyImageFiles.nodes.forEach((node) => {
    const pathWithoutSlug = replaceSlugFromPath(node.relativePath, slugWithoutSlashes);
    localFiles[pathWithoutSlug] = {
      static: false,
      target: node.childImageSharp.gatsbyImageData,
    };
  });

  publicURLFiles.nodes.forEach((node) => {
    const pathWithoutSlug = replaceSlugFromPath(node.relativePath, slugWithoutSlashes);
    localFiles[pathWithoutSlug] = {
      static: true,
      target: node.publicURL,
    };
  });

  const theme = useTheme();

  return (
    <Layout>
      <Helmet>
        <link rel="stylesheet" href="https://cdn.materialdesignicons.com/3.3.92/css/materialdesignicons.min.css" />

        <style type="text/css">
          {`
            figure {
              text-align: center !important;
              max-width: 1000px;
              margin-left: auto;
              margin-right: auto;
              margin-top: 1.5em;
              margin-bottom: 1.5em;
            }

            footer.footer {
              display: none;
            }

            /* Overrides of previous html/style.css */
            blockquote.blockquote {
              margin: 1.25em auto 1.25em;
              color: ${theme.custom.blockquote.text};
              border: 1px solid ${theme.custom.blockquote.border};
              border-left: 10px solid ${theme.custom.blockquote.borderLeft};
              background-color: ${theme.custom.blockquote.background};
              border-radius: 0 .3em .3em 0 !important;
            }

            blockquote::before {
              color: ${theme.custom.blockquote.quoteSign};
            }

            .project-image {
              border: none;
            }

            section img, .algorithm {
              box-shadow: none;
            }

            .algorithm {
              border: 1px solid #555555;
              border-radius: 4px;
              background: rgba(8, 8, 8, 0.8);
            }

            @keyframes linkAnimation {
              5% {
                  filter: blur(1px);
                  transform: scale(1.025, 1.2);
              }
            }
            #content a:hover {
                animation: linkAnimation 0.15s ease-in;
            }
            #content a::after {
              background: ${theme.palette.primary.main} !important;
            }

            /* Bootstrap classes */
            .text-center {
              text-align: center !important;
            }

            .figure-img {
              margin-bottom: .5rem;
              line-height: 1;
            }

            .m-3 {
              margin: 1rem !important;
            }
          `}
        </style>
      </Helmet>
      <Box>
        {renderAst(htmlRehype.htmlAst)}
      </Box>
    </Layout>
  );
}

export const postQuery = graphql`
  query StaticHTMLQuery($id: String!, $slug: String!) {
    htmlRehype(id: { eq: $id }) {
      htmlAst
      fields {
        slug
      }
    }

    # gif images don't have childImageSharp: "The childImageSharp portion of the query in this file will return null"
    gatsbyImageFiles: allFile(filter: {fields: {slug: {eq: $slug}}, internal: {mediaType: {regex: "/image\/(?!gif|svg)/"}}}) {
      nodes {
        publicURL
        relativePath
        internal {
          mediaType
        }
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: AUTO # smoother gradient with this option
            quality: 100
          )
        }
      }
    }

    publicURLFiles: allFile(filter: {fields: {slug: {eq: $slug}}, ext: {regex: "/gif|svg|mp4|sql|json/"}}) {
      nodes {
        publicURL
        relativePath
      }
    }
  }
`;

HtmlReactRehyped.propTypes = {
  data: PropTypes.shape({
    htmlRehype: PropTypes.shape({
      htmlAst: PropTypes.objectOf(PropTypes.object),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,

    gatsbyImageFiles: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
        relativePath: PropTypes.string.isRequired,
        internal: PropTypes.shape({
          mediaType: PropTypes.string.isRequired,
        }).isRequired,
        childImageSharp: PropTypes.shape({
          gatsbyImageData: PropTypes.objectOf(PropTypes.object),
        }).isRequired,
      })).isRequired,
    }).isRequired,

    publicURLFiles: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
        relativePath: PropTypes.string.isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired,
};
